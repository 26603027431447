import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { faEdit, faUserMd, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FEET, FOOT, KNEE, KNEES } from "../../utils/constants";
import { Field, Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactSelectField } from "../shared/FieldInput";
import Api from "../../api/Api";
import ItemGroup from "../shared/ItemGroup";
import ItemGroupTitle from "../shared/ItemGroupTitle";
import ItemProperty from "../shared/ItemProperty";
import React, { useEffect, useState } from "react";
import Split from "../shared/Split";
import TokenService from "../../utils/TokenService";
import ViewHeader from "../shared/ViewHeader";

const DeviceView = () => {
  const { id, deviceType } = useParams();
  const [device, setDevice] = useState();
  const [editingFirmware, setEditingFirmware] = useState();
  const toggleEditingFirmware = () => setEditingFirmware(!editingFirmware);
  const [firmware, setFirmware] = useState([]);
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deviceSubstitution = (val) =>
    val === KNEES ? KNEE : val === FEET ? FOOT : null;

  const getFirmware = () =>
    Api.get(`${deviceSubstitution(deviceType)}_firmware`).then((res) => {
      let data = res.data.firmware;
      setFirmware([
        { label: "Always use latest", value: null },
        ...data.reduce(function(filtered, device) {
          if (TokenService.isUserBetaAdmin() || !device.is_test_firmware) {
            filtered.push({
              label: `${device.version}${device.is_test_firmware ? " (test)" : ""}`,
              value: device.id
            });
          }
          return filtered;
        }, []),
      ]);
    });

  const getDevice = () =>
    Api.get(`${deviceType}/${id}`).then((res) => setDevice(res.data.device));

  const updateDevice = async (vals) =>
    Api.patch(`devices/${id}`, {
      ...vals,
      preferred_firmware_id: vals.preferred_firmware_id.value,
    }).then(() => {
      setEditingFirmware();
      getDevice();
    });

  const getCalDataValue = (data) => data !== undefined && data !== null ? data : 'N/A';

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const deleteDevice = async () =>
    Api.delete(`devices/${id}`).then(() => navigate("/devices/" + deviceType));

  const validDeviceTypes = [FEET, KNEES];

  useEffect(() => {
    if (!validDeviceTypes.includes(deviceType)) navigate("/");
    getDevice();
    getFirmware();
  }, [deviceType]);

  return device ? (
    <Container>
      <ViewHeader>{device?.serial_number}</ViewHeader>
      <ItemGroupTitle title="Details" />
      <ItemGroup>
        <ItemProperty
          label="Current Firmware Version"
          value={device?.firmware_version || "None set"}
        />
        <ItemProperty required label="Preferred Firmware">
          {editingFirmware ? (
            <Form
              onSubmit={updateDevice}
              initialValues={{
                preferred_firmware_id: {
                  label:
                    device?.preferred_firmware_version || "Always use latest",
                  value: device?.preferred_firmware_id,
                },
              }}
              render={({ handleSubmit, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Field
                        name="preferred_firmware_id"
                        component={ReactSelectField}
                        options={firmware}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="text-right">
                      <Button
                        color="danger"
                        className="uppercase fw-bold"
                        onClick={toggleEditingFirmware}>
                        Cancel
                      </Button>
                      {!pristine && (
                        <Button type="submit" className="btn-cp-secondary">
                          Update
                        </Button>
                      )}
                    </Col>
                  </Row>
                </form>
              )}></Form>
          ) : (
            <Row className="mt-2 pt-2">
              <Col>
                <Split>
                  <h5>
                    {device?.preferred_firmware_version || "Always use latest"}
                  </h5>
                  <h5 className="pop-hover-sm" onClick={toggleEditingFirmware}>
                    <FontAwesomeIcon icon={faEdit} />
                  </h5>
                </Split>
              </Col>
            </Row>
          )}
        </ItemProperty>
        <ItemProperty label="Connected User" required>
          <p id="user">
            {device?.user_id ? (
              <Link
                to={`/users/${device?.user_id}`}
                className="text-decoration-none text-dark">
                {device?.user_is_admin ? (
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />
                ) : (
                  <FontAwesomeIcon icon={faUserMd} className="me-2" />
                )}
                {device?.user_full_name}
              </Link>
            ) : (
              "No configured user"
            )}
          </p>
        </ItemProperty>
        {/* Hiding attachments view since this is not implemented on mobile ~ rj 06/2023 */}
        {/* <ItemProperty
          required
          label={"Attachments (" + device?.attachments.length + ")"}>
          {device?.attachments.map((a, x) => (
            <Split key={x} className="p-2">
              <div>
                <h5>{a.name}</h5>
                <p className="text-secondary">{userFriendlyFileSize(a)}</p>
              </div>
              <h5
                className="p-2 pop-hover"
                title={"Download " + a.name}
                onClick={() =>
                  Api({
                    method: "GET",
                    url: "/attachments/" + a.id + "/download",
                    responseType: "blob",
                  }).then((response) => fileDownload(response.data, a.name))
                }>
                <FontAwesomeIcon icon={faDownload} />
              </h5>
            </Split>
          ))}
        </ItemProperty> */}
      </ItemGroup>
      {deviceType === FEET && (
        <>
          <ItemGroupTitle title="Calibration Data" />
          <ItemGroup>
            <ItemProperty label="Open Dorsiflexion Resistance"
                          value={getCalDataValue(device?.cal_data?.open_dorsiflexion_resistance)}>
            </ItemProperty>
            <ItemProperty label="Closed Dorsiflexion Resistance"
                          value={getCalDataValue(device?.cal_data?.closed_dorsiflexion_resistance)}>
            </ItemProperty>
            <ItemProperty label="Open Plantarflexion Resistance"
                          value={getCalDataValue(device?.cal_data?.open_plantarflexion_resistance)}>
            </ItemProperty>
            <ItemProperty label="Closed Plantarflexion Resistance"
                          value={getCalDataValue(device?.cal_data?.closed_plantarflexion_resistance)}>
            </ItemProperty>
            <ItemProperty label="Toe Heel Offset" value={getCalDataValue(device?.cal_data?.toe_heel_offset)}>
            </ItemProperty>
            <ItemProperty label="X Gyroscope Offset" value={getCalDataValue(device?.cal_data?.x_gyroscope_offset)}>
            </ItemProperty>
            <ItemProperty label="Foot Angle Offset" value={getCalDataValue(device?.cal_data?.foot_angle_offset)}>
            </ItemProperty>
            <ItemProperty label="Flat Foot Acceleration" value={getCalDataValue(device?.cal_data?.flat_foot_acceleration)}>
            </ItemProperty>
          </ItemGroup>
        </>
      )}
      <div className="d-flex justify-content-center">
        <Button
          className="fw-bold uppercase"
          color="danger"
          onClick={toggleDeleteModal}>
          Delete Device
        </Button>
      </div>
      <Modal centered isOpen={isDeleteModalOpen}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          Continuing will delete this device from the system. Performance of the device will not be affected or
          interrupted, it will simply be treated as a new device the next time a user connects to it.
          <br />
          <br />
          Only proceed if this device was provisioned by a user in error.
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggleDeleteModal}
            className="btn-cp fw-bold uppercase">
            Cancel
          </Button>
          <Button
            onClick={deleteDevice}
            color="danger"
            className="fw-bold uppercase">
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  ) : (
    <Row>
      <Col className="text-center m-4">Loading...</Col>
    </Row>
  );
};

export default DeviceView;
