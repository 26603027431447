import { Button, Col, Container, Row } from "reactstrap";
import { FOOT, KNEE } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../api/Api";
import React, { useEffect, useState } from "react";
import Split from "../shared/Split";
import Table from "../table/Table";
import TokenService from "../../utils/TokenService";

const FirmwareList = () => {
  const { deviceType } = useParams();
  const navigate = useNavigate();
  const navigateToFirmware = (id) => navigate(`/firmware/${deviceType}/${id}`);
  const [firmware, setFirmware] = useState([]);

  const mapFirmware = (firmware) => {
    firmware.test = firmware.is_test_firmware ? "🛠️" : "";
    if (firmware.device_count === 0) {
      firmware.device_count = "0";
    }
    return firmware;
  };

  const getFirmware = () => {
    Api.get(`/${deviceType}_firmware`).then((res) =>
      setFirmware(res.data.firmware.map(mapFirmware) || [])
    );
  };

  const validDeviceTypes = [FOOT, KNEE];

  // get initial data
  useEffect(() => {
    if (!validDeviceTypes.includes(deviceType)) {
      navigate("/");
    }
    getFirmware();
  }, [deviceType]);

  const columns = [
    { label: "Version", accessor: "version", searchable: true },
    { label: "Test Firmware?", accessor: "test" },
    { label: "Main Board Version", accessor: "main_version", searchable: true },
    { label: "Bluetooth Version", accessor: "bluetooth_version", searchable: true },
    { label: "Device Count", accessor: "device_count" },
    { label: "Size", accessor: "size" },
    { label: "Created", accessor: "created" },
  ];

  return (
    <Container>
      <Row>
        <Col className="cp-header-text">
          <Split>
            <h1>
              {deviceType?.charAt(0).toUpperCase() + deviceType?.slice(1)}{" "}
              Firmware
            </h1>
            {TokenService.isUserBetaAdmin() &&
              <div onClick={() => navigate(`/firmware/${deviceType}/upload`)}>
                <Button className="btn-cp-secondary">Upload</Button>
              </div>
            }
          </Split>
        </Col>
      </Row>
      <Row>
        <Table
          columns={columns}
          data={firmware}
          rowClick={navigateToFirmware}
        />
      </Row>
    </Container>
  );
};

export default FirmwareList;
