import { Button, Col, Row } from "reactstrap";
import { Field, Form } from "react-final-form";
import Api from "../../api/Api";
import FieldLabel from "../shared/FieldLabel";
import React from "react";
import toast from "react-hot-toast";

const UpdatePasswordForm = () => {
  const required = (value) => (value ? undefined : "Required");
  const oneLowercaseLetter = (value) =>
    value.match(/[a-z]/) ? undefined : "Must contain a lowercase letter";
  const oneUppercaseLetter = (value) =>
    value.match(/[A-Z]/) ? undefined : "Must contain an uppercase letter";
  const oneNumber = (value) =>
    value.match(/\d/) ? undefined : "Must contain a number";
  const atLeastEightChars = (value) =>
    value.length > 7 ? undefined : "Must be at least 8 characters";
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

  const submit = async (vals, form) => {
    Api.patch("/update-password", { ...vals }).then(() => {
      toast.success("Your password was successfully updated");
      Object.keys(vals).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });
    });
  };

  return (
    <>
      <h3>Update Password</h3>
      <Form
        onSubmit={submit}
        initialValues={{}}
        validate={(values) => {
          const errors = {};
          if (
            values.current_password &&
            values.password &&
            values.current_password === values.password
          )
            errors.password = "New password cannot be current password";
          if (values.password != values.password_confirmation) {
            errors.password_confirmation = "New passwords must match";
          }
          return errors;
        }}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <FieldLabel label="Current Password"></FieldLabel>
                <Field
                  id="current-password"
                  name="current_password"
                  required
                  className="cp-input"
                  component="input"
                  type="password"
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FieldLabel label="New Password"></FieldLabel>
                <Field
                  name="password"
                  validate={composeValidators(
                    required,
                    oneLowercaseLetter,
                    oneUppercaseLetter,
                    oneNumber,
                    atLeastEightChars
                  )}>
                  {({ input, meta }) => (
                    <div>
                      <input {...input} className="cp-input" type="password" autoComplete="off" />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </Col>
              <Col md="6">
                <FieldLabel label="Confirm New Password"></FieldLabel>
                <Field name="password_confirmation" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <input {...input} className="cp-input" type="password" autoComplete="off" />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="my-4">
              <Col className="text-secondary">
                <h5>Requirements</h5>
                <ul>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 lowercase letter</li>
                  <li>At least 1 number</li>
                  <li>At least 8 characters long</li>
                </ul>
              </Col>
            </Row>
            <Row className="my-4">
              <Col className="text-right">
                <Button disabled={pristine} className="btn-cp" type="submit">
                  Update
                </Button>
              </Col>
            </Row>
          </form>
        )}></Form>
    </>
  );
};

export default UpdatePasswordForm;
